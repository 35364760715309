<template>
    <div class="main bildungs-index show-bulletpoints-normal">
        <div class="main-sidebar" v-if="$isDesktopScreen">
            <div class="main-sidebar__head">
                <h4 class="title">{{title}}</h4>
                <p>{{description}}</p>
            </div>

            <div v-if="tempShowHide" class="main-sidebar__filter">
                <div v-show="!expandFilter">
                    <keyword-search class="mb20" :initialValue="dataSet.query.meilisearch" @searchWordChanged="searchWordChanged"/>

                    <KitaOrSchuleSelect class="mb15" name="Kategorie" :selected="selectedCategory" @on-select="reloadContents"/>

                    <WBSelectField v-if="primarilaryFilterSelection!==null" v-for="(item, index) in primarilaryFilterSelection" :key="item.field_type_id" class="mb15" :name="item.label" :field-type-id="item.field_type_id"  :values="item.options" :selectedFilters="selectedFilters" :isMultiple="true" @on-select="bildungsFilterChanged" />

                    <button class="btn btn-link text-primary mb20" @click="resetAllFilters">Filter zurücksetzen</button>
                    <button class="btn btn-link float-right text-primary mb20" v-if="moreFilterSelection!==null && moreFilterSelection.length>0" @click="filterModal = true">Mehr Filter</button>

                    <div class="clearfix"></div>
                </div>

                <button @click="expandFilter = false" v-if="expandFilter" class="btn btn-expand btn-link" ref="searchExpand">Suche und Filter ausklappen</button>
                <button @click="expandFilter = true" v-else class="btn btn-expand btn-link">Suche und Filter einklappen</button>
            </div>

            <div class="main-sidebar__content" v-if="dataSet.data!==null && dataSet.data.length>0">
                <!-- data will be here -->
                <h6>{{ dataSet.total }} Ergebnisse</h6>
                <map-card v-for="(item, index) in schulen" :key="index" :item="item" :autoWidth="true" @highlight="highlightThisContent" :id="'vertical-content-'+item.id"/>
            </div>

            <div class="main-sidebar__content" v-if="!searching && (dataSet.data==null || dataSet.data.length==0)">
                <div class="search-no-results">
                    <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                    <h5 class="mt20">Keine Ergebnisse gefunden</h5>
                </div>
            </div>

        </div>
        <div class="main-content" id="main-scroll-container">
            <TitleBar v-if="contentType!==null && $isMobileScreen" 
            karteLink="/bildung" :filters="allFilters" :selectedFilters="selectedFilters" 
            listeLink="/bildung?show=liste" :showButtons="true" :showMapOrList="showMap" 
            :filterMenu="isKitaActive ? 'kita' : 'schul'" :isFixed="true" :contentCount="tempContentCount" 
            :initialKeywordSearchValue="dataSet.query.meilisearch" @contentFilteringFinished="filteringFinished"
            @resetAllFilters="resetAllFilters" @getNumberOfResults="getNumberOfResults" @searchWordChanged="searchWordChanged"/>

            <KitaOrSchuleButtons v-if="false" :isKitaActive="isKitaActive" @selectedButton="reloadContents"/>

            <div v-if="showMap" class="karte">

                <multiple-map ref="theMap" class="the-karte" :contents="dataSet.data" @highlight="highlightThisContent"/>

                <div class="karte-cards" v-if="dataSet.data!==null && dataSet.data.length>0 && $isMobileScreen">
                    <div class="container-fluid">
                        <div class="row mobile--scrolling horizontal-scroll">
                            <map-card v-for="(item, index) in schulen" :key="index" :item="item" @highlight="highlightThisContent" :id="'horizontal-content-'+item.id"/>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="list-section section">
                <div class="container-fluid">
                    <div class="row" v-if="dataSet.data!==null && dataSet.data.length>0">
                        <div class="col-12 col-md-6 col-lg-4" v-for="(item, index) in dataSet.data" :key="index">
                            <bildung-card :item="item"/>
                        </div>
                    </div>

                    <div v-else-if="!searching" class="search-no-results center">
                        <img src="/assets/search-no-result.png" alt="Keine Suchergebnisse">
                        <h5 class="mt20">Keine Ergebnisse gefunden</h5>
                    </div>
                </div>
            </div>

        </div>

        <kita-or-schule-modal v-if="showModal" @selectedCard="reloadContentsAndFocus"/>

        <WBMoreFilters :showModal="filterModal" @on-close="updateOnClose"
        :contentCount="tempContentCount" :selectedFilters="selectedFilters" @getNumberOfResults="getNumberOfResults" 
        @filteringFinished="filteringFinished" :filters="moreFilterSelection" 
        v-if="moreFilterSelection!==null"/>

    </div>
</template>

<script>
    import Resource from '@/api/resource';
    const contentTypeResource = new Resource('contenttypes');
    import { getSublocalities } from '@/api/system';
    import { getContentsOfType } from '@/api/content';
    import { showLoader, hideLoader, getFilterFields, createSelectOptions, getAllowedValuesFromFieldType, argsFilterMapToJSMap, isset, getFirstFieldValue } from '@/utils/helpers';
    import { screenSizeMixin } from '@/mixins/screenSizeMixin';
    import VueScrollTo from 'vue-scrollto';

    export default {
        name: "BildungsFinder",
        mixins: [screenSizeMixin],
        components: {
         MapCard: () => import('./components/BildungMapCard.vue'),
         BildungCard: () => import('@/components/cards/Bildung.vue'),
         TitleBar: () => import('@/components/controls/TitleBar/TitleBar.vue'),
         KitaOrSchuleModal: () => import('./components/KitaOrSchuleModal.vue'),
         KitaOrSchuleSelect: () => import('./components/KitaOrSchuleSelect.vue'),
         KitaOrSchuleButtons: () => import('./components/KitaOrSchuleButtons.vue'),
         WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
         WBMoreFilters: () => import('@/components/modals/WBMoreFilters.vue'),
         MultipleMap: () => import('@/components/map/multiple.vue'),
         KeywordSearch: () => import('@/components/controls/KeywordSearch'),
     },
     data() {
        return {
            showModal: true,
            tempShowHide: true,
            tempContentCount: 0,
            contentType: null,
            searching: true,
            sublocalities: null,
            dataSet: {
              total: 0,
              data: [],
              meta:null,
              query: {
                page:1,
                type: "view",
                limit: 500,
                meilisearch: '',
                filters: null,
                selectedContentTypeId: this.$schulId,
                view_status: 1,
                sort: {
                  prop: '',
                  order: 'asc'
              },
          },
      },
      selectedCategory: null,
      selectedFilters: new Map(),
      expandFilter: true,
      filterModal: false,
      scrollVerticalOptions: {
        container: '.main-sidebar__content',
        easing: 'ease-in',
        lazy: false,
        offset: -60,
        force: true,
        cancelable: true,
        x: false,
        y: true,
    },
    scrollHorizontalOptions: {
        container: '.horizontal-scroll',
        easing: 'ease-in',
        lazy: false,
        offset: -60,
        force: true,
        cancelable: true,
        x: true,
        y: false,
    },
}
},
created(){
    if(this.urlCategory !== null){
        this.showModal = false;
        this.selectedBildungsArea(this.urlCategory);
    }
    else {
        this.showModal = true;
        return;
    }

    var filters = argsFilterMapToJSMap(this.uriFilters);
    if(isset(filters)){
        this.filteringFinished(filters);
    }
    this.getContents();
},
computed: {
    schulen(){
        var grundschulen = new Array();
        var foerderschuleGeistig = new Array();
        var foerderschuleKoerperl = new Array();
        var integrierteGesamtschulen = new Array();
        var hauptschulen = new Array();
        var realschulen = new Array();
        var oberschulen = new Array();
        var gymnasien = new Array();
        var berufsbildendenSchulen = new Array();
        

        for (let i=0; i<this.dataSet.data.length;i++){
            var schule = this.dataSet.data[i];
            var value = getFirstFieldValue(schule,'schulart');

            switch(value) {
                case "Grundschule":
                grundschulen.push(schule);
                break;
                case "Hauptschule":
                hauptschulen.push(schule);
                break;
                case "Realschule":
                realschulen.push(schule);
                break;
                case "Oberschule":
                oberschulen.push(schule);
                break;
                case "Förderschule für körperliche und motorische Entwicklung":
                foerderschuleKoerperl.push(schule);
                break;
                case "Förderschule für geistige Entwicklung":
                foerderschuleGeistig.push(schule);
                break;
                case "Gymnasium":
                gymnasien.push(schule);
                break;
                case "Integrierte Gesamtschule":
                integrierteGesamtschulen.push(schule);
                break;
                case "Berufsbildende Schulen":
                berufsbildendenSchulen.push(schule);
                break;
            }
        }
        grundschulen.sort((a, b) => a.title.localeCompare(b.title));
        hauptschulen.sort((a, b) => a.title.localeCompare(b.title));
        realschulen.sort((a, b) => a.title.localeCompare(b.title));
        oberschulen.sort((a, b) => a.title.localeCompare(b.title));
        foerderschuleKoerperl.sort((a, b) => a.title.localeCompare(b.title));
        foerderschuleGeistig.sort((a, b) => a.title.localeCompare(b.title));
        gymnasien.sort((a, b) => a.title.localeCompare(b.title));
        integrierteGesamtschulen.sort((a, b) => a.title.localeCompare(b.title));
        berufsbildendenSchulen.sort((a, b) => a.title.localeCompare(b.title));

        var sortierteSchulen = grundschulen.concat(foerderschuleGeistig, foerderschuleKoerperl, integrierteGesamtschulen, hauptschulen, realschulen, oberschulen, gymnasien, berufsbildendenSchulen);

        return sortierteSchulen;
    },
    uriFilters(){
        return this.$route.query.filters;
    },
    title(){
        if(this.isKitaActive){
            return "KitaFinder";
        }
        return "Schulfinder"
    },
    currentContentTypeId(){
        if(this.isKitaActive){
            return this.$kitaId;
        }
        return this.$schulId;
    },
    isKitaActive(){
        if(this.selectedCategory!=null && this.selectedCategory.value=='kita'){
            return true;
        }
        return false;
    },
    description(){
        if(this.isKitaActive){
            return "Kita-Finder: Lorem ipsum dolor sit ...";
        }
        return "Finde eine passende Schule.";
    },
    showMap() {
        if(this.$isDesktopScreen){
            return true;
        }

        if(Object.prototype.hasOwnProperty.call(this.$route.query,'show')){
            return false;
        }
        return true;
    },
    urlCategory() {
        if(Object.prototype.hasOwnProperty.call(this.$route.query,'target')){
            if(this.$route.query.target!== undefined){
                if(this.$route.query.target.toLowerCase() == "kita"){
                    return "kita";
                }
                else{
                    return "schule";
                }
            }
        }
        return null;
    },
    filterableFields(){
        return getFilterFields(this.contentType);
    },
    allFilters(){
        var filters = [];
        if(this.primarilaryFilterSelection!==null && this.primarilaryFilterSelection.length>0){
            filters = filters.concat(this.primarilaryFilterSelection);
        }

        if(this.moreFilterSelection!==null && this.moreFilterSelection.length>0){
            filters = filters.concat(this.moreFilterSelection);
        }

        return filters.length>0?filters:null;

    },
    moreFilterSelection(){
        var filters = [];

        if(this.sublocalities!==null){
         var bla = {};
         bla.field_type_id="location";
         bla.label = "Stadtteile";
         bla.options = []
         for(var i = 0; i < this.sublocalities.length; i++){
            bla.options.push({label:this.sublocalities[i].sublocality,value:this.sublocalities[i].sublocality});
        }
        filters.push(bla);
    }

    if(this.filterableFields!==null && this.filterableFields.length>2){
        var cloneArray = this.filterableFields.slice();
        var selection = cloneArray.splice(2,cloneArray.length);
        for(var i = 0; i < selection.length; i++){
            /*check if we have values*/
            var values = getAllowedValuesFromFieldType(selection[i]);
            if(values !== null){
                var bla = {};
                bla.field_type_id = selection[i].id;
                bla.label = selection[i].name;
                bla.options = createSelectOptions(values);
                filters.push(bla);
            }
        }
        return filters;
    }
    return null;
},
primarilaryFilterSelection(){
    var filters = [];
    if(this.filterableFields!==null && this.filterableFields.length>=2){
        var cloneArray = this.filterableFields.slice();
        var selection = cloneArray.splice(0,2);
        for(var i = 0; i < selection.length; i++){
            /*check if we have values*/
            var values = getAllowedValuesFromFieldType(selection[i]);
            if(values !== null){
                var bla = {};
                bla.field_type_id = selection[i].id;
                bla.label = selection[i].name;
                bla.options = createSelectOptions(values);
                filters.push(bla);
            }
        }
        return filters;
    }
    return null;
},
},
methods: {
    getFirstFieldValue,
    showLoader, 
    hideLoader,
    getSublocalities, 
    createSelectOptions,
    getAllowedValuesFromFieldType,
    reloadContentsAndFocus(val){
        this.reloadContents(val);
        this.$refs.searchExpand.focus();
    },
    reloadContents(val){
        this.selectedCategory = val;
        this.dataSet.query.filters = null;
        this.dataSet.query.meilisearch = '';
        this.selectedBildungsArea(val);
        this.getContents();    
    },
    selectedBildungsArea(val){
        this.dataSet.query.selectedContentTypeId = this.currentContentTypeId;
        if(val !== null && val !== undefined && Object.prototype.hasOwnProperty.call(val,'value') && val.value !== null && val.value !== undefined) {
            history.pushState({},null,this.$route.path + '?target=' + val.value);
        }
        this.$scrollTo();
        this.getContentType();
        this.getTheSublocalities();
    },
    bildungsFilterChanged(fieldTypeId,values){
        this.selectedFilters.set(fieldTypeId, values);
        this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
        this.getContents();
    },
    searchWordChanged(word){
        this.dataSet.query.meilisearch = word;
        this.getContents();
    },
    getNumberOfResults(map){
        /*tempFilters will contain all the filters set for now but only for the purpose of calculating the number of items found for these filtersettings*/
        var tempFilters = new Map([...this.selectedFilters,...map]);
        /*#todo: we'll have to start a call to the backend to find out how many values there are for this selection*/
        this.getNumberOfContentsMatchingTheFilter(tempFilters);
    },
    filteringFinished(map){
        /*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
        this.selectedFilters = map;
        this.filterModal = false;
        this.dataSet.query.filters = JSON.stringify(Array.from(this.selectedFilters.entries()));
        this.getContents();
    },
    resetAllFilters(){
        this.tempShowHide = false;
        this.filteringFinished(new Map());
    },
    updateOnClose() {
        this.filterModal = false;
    },
    getContentType() {
        this.contentType = null;
        var id = this.currentContentTypeId;
        this.loader = this.showLoader(this.loader);
        /*overwrite the existing filters*/
        this.selectedFilters = new Map();
        contentTypeResource.get(id).then(response => {
            this.contentType = response.data;
        })
        .finally(() => {
            this.loader = this.hideLoader(this.loader);
        });
    },
    async getTheSublocalities() {
        var id = this.currentContentTypeId;
        getSublocalities(this.$city,null, id).then(response => {
            this.sublocalities = response;
        });
    },
    getContents() {
        this.searching = true;
        history.pushState({},null,this.$route.path + '?target=schule&filters=' + encodeURIComponent(this.dataSet.query.filters));
        this.dataSet.query.type="view";
        var contentLoader = this.showLoader(contentLoader);
        const { limit, page } = this.dataSet.query;
        getContentsOfType(this.currentContentTypeId,this.dataSet.query).then(response => {
            const { data, meta } = response;
            this.dataSet.data = data;
            this.dataSet.data.forEach((element, index) => {
              element['index'] = (page - 1) * limit + index + 1;
          });
            this.dataSet.meta = meta;
            this.dataSet.total = meta.total;
            this.tempContentCount = meta.total;
        })
        .finally(() => {
            contentLoader = this.hideLoader(contentLoader);
            document.getElementById('main-scroll-container').scroll({top:0,behavior:'smooth'});
            this.tempShowHide = true;
            this.searching = false;
        });
    },
    getNumberOfContentsMatchingTheFilter(tempFilters){
        this.dataSet.query.type="count";
        this.dataSet.query.filters = JSON.stringify(Array.from(tempFilters.entries()));
        var contentLoader = this.showLoader(contentLoader);
        getContentsOfType(this.currentContentTypeId,this.dataSet.query).then(response => {
            this.tempContentCount = response;
        })
        .finally(() => {
            contentLoader = this.hideLoader(contentLoader);
        });
    },
    highlightThisContent(params){
        Object.keys(this.dataSet.data).forEach((k) => {
            this.dataSet.data[k].highlight = false;
            if(this.dataSet.data[k].id == params.content.id){
                this.dataSet.data[k].highlight = true;

                if(params.sender != "map"){
                    /*open the popup*/
                    this.$refs.theMap.openPopup(params.content);
                }
                else{
                    if(this.$isMobileScreen){
                        VueScrollTo.scrollTo(('#horizontal-content-'+this.dataSet.data[k].id), '2000',this.scrollHorizontalOptions);
                    }
                    else{
                        VueScrollTo.scrollTo(('#vertical-content-'+this.dataSet.data[k].id), '2000',this.scrollVerticalOptions);
                    }
                }
            }
        });
    }
}
};
</script>
<style lang="scss">
    @import '@/scss/_variables.scss';
    .bildungs-index{

        .search-no-results{
            margin-top: 40px;
            width: 100%; 
            text-align: center;

            img{
                width: 110px;
            }  
        }

        .the-karte{
            @media (max-width: 991px) {
                margin-top: 64px;
            }
        }

        .karte{
            @media (max-width: 991px) {
                overflow: hidden;            
            }
        }

        .karte-cards{
            width: 100%;
            bottom: 0;
            z-index: 1001;
            position: absolute;
        }

        .list-section{
            @media (max-width: 991px) {
                padding: 0px;
            }
        }

        .main-sidebar_scroll{
            display: flex;
            flex-wrap: nowrap;
            flex-direction: column;
            height: calc(100vh - 320px);
            scrollbar-width: none;
        }

        .main-sidebar__content{
            overflow-y: auto;
        }
    }
</style>
